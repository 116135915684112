<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>
      {{ pageTitle }}
    </h1>
    <TestForm :info="test" :cannotEdit="false" />
    <div style="text-align: center">
      <el-button style="margin: 0px 4px" type="primary" @click="updateTest">
        {{ $t("button.save") }}
      </el-button>
    </div>
    <hr class="separate-line" />
    <SectionTable
      :testType="$route.params.testType"
      :testTypeId="$route.query.testTypeId"
    />
  </div>
</template>

<script>
import testsApi from "@/apis/tests.js";
import TestForm from "@/components/tests/TestForm.vue";
import Breadcrumb from "@/components/Breadcrumb";
import SectionTable from "@/components/tests/SectionTable.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    TestForm,
    Breadcrumb,
    SectionTable
  },
  props: [],
  computed: {
    dontNeedPassageSection() {
      return ["math", "math calculator"];
    },
    pageTitle() {
      return this.testName;
    }
  },
  data() {
    return {
      testName: "",
      test: {},
      section_type: {
        reading: "Reading",
        grammar: "Grammar",
        math: "Math(Without Calculator)",
        mathc: "Math(With Calculator)",
        essay: "Essay"
      },
      sections: []
    };
  },
  async created() {
    this.test = await testsApi.getTest(
      this.$route.params.testType,
      this.$route.query.testTypeId
    );
    this.sections = this.test.subjects;
    this.testName = this.test.full_name;
  },
  methods: {
    async updateTest() {
      try {
        await testsApi.updateTestInfo(
          this.$route.params.testType,
          this.$route.query.testTypeId,
          {
            title: this.test.title,
            full_name: this.test.full_name,
            url: {
              test: this.test.url.test
            },
            test_book_ids: this.test.test_book_ids
          }
        );
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style>
kbd {
  background-color: #000;
  border-color: #000;
  color: #fff;
  padding: 0 10px;
}
</style>
<style scoped>
.container >>> .el-dialog__body {
  padding: 10px 20px;
}
.ivy-content {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}
.view-passage >>> .el-collapse-item__content {
  font-size: 16px;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
.ivy-passage {
  height: calc(100vh - 330px);
  overflow: auto;
  padding-right: 20px;
}
.line {
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>
